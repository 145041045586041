$primary: #ed1b2f;
$secondary: #142B35;
$red: blue;
$green: blue;
$cardTitleColor: #434343;
$cardLinkColor: #555555;
$fontFamilyRegular: "Eurostile";
$fontFamilyBlack: "EurostileBla";
$fontFamilyMedium: "EurostileMed";
$bgLight: #F5F5F5;
$tabInnerBg: #FAFAFA;

$tabLinkColor: #7B7B7B;
$smNavItemBorder: #DEDEDE;

$table-striped-alt-bg: #FFFFFF;
$table-striped-bg: #F0F0F0 !default;
$tabBg: #F0F0F0;
$carouselIndicator: #C2C2C2;
$carouselIndicatorActive: $secondary;

$fontSize: 1rem;


// footer

$footerLinkColor: #fff;
$footerDividerColor: #fff;

