@import "../bootstrap/scss/functions";
@import "../bootstrap/scss/variables";
@import "../bootstrap/scss/mixins";
@import "../bootstrap/scss/utilities";

// Layout & components
@import "../bootstrap/scss/root";
@import "../bootstrap/scss/reboot";
@import "../bootstrap/scss/type";
@import "../bootstrap/scss/images";
@import "../bootstrap/scss/containers";
@import "../bootstrap/scss/grid";
@import "../bootstrap/scss/tables";
@import "../bootstrap/scss/forms";
@import "../bootstrap/scss/buttons";
@import "../bootstrap/scss/transitions";
@import "../bootstrap/scss/dropdown";
/*@import ".//static//static/assets/bootstrap/scss/button-group";*/
@import "../bootstrap/scss/nav";
@import "../bootstrap/scss/navbar";
@import "../bootstrap/scss/card";
/*@import ".//static//static/assets/bootstrap/scss/card";*/
@import "../bootstrap/scss/accordion";
@import "../bootstrap/scss/breadcrumb";
@import "../bootstrap/scss/badge";
@import "../bootstrap/scss/modal";
/*@import ".//static//static/assets/bootstrap/scss/pagination";
@import ".//static//static/assets/bootstrap/scss/badge";
@import ".//static//static/assets/bootstrap/scss/alert";
@import ".//static//static/assets/bootstrap/scss/progress";
@import ".//static//static/assets/bootstrap/scss/list-group";
@import ".//static//static/assets/bootstrap/scss/close";
@import ".//static//static/assets/bootstrap/scss/toasts";
@import ".//static//static/assets/bootstrap/scss/modal";*/
@import "../bootstrap/scss/tooltip";
/*@import ".//static//static/assets/bootstrap/scss/popover";*/
@import "../bootstrap/scss/carousel";
@import "../bootstrap/scss/spinners";
// @import ".//static//static/assets/bootstrap/scss/offcanvas";
// @import ".//static//static/assets/bootstrap/scss/placeholders";

// Helpers
@import "../bootstrap/scss/helpers";

// Utilities
@import "../bootstrap/scss/utilities/api";

@import "./variables";
@import "./root";
@import "./fonts";

// override
@import "../bootstrap/scss/mixins";

//page stretch added on 05/19/2022
html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

a {
  color: #e74c3c;
}

a:hover {
  color: #a6382c;
}

.bg-light-gray {
  background-color: #efefef;
}

//
.btn-outline-dark {
  color: $cardLinkColor;
  border-color: transparent;

  &:hover {
    .svg-filter-dark {
      filter: invert(97%) sepia(100%) saturate(0%) hue-rotate(55deg)
        brightness(104%) contrast(105%);
    }
  }
}

.tooltip {
  font-size: 0.75rem;
}

.tooltip-inner {
  text-align: left;
  background-color: $primary;
  border-radius: 0;
  padding: 0.5rem;
}

.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: $primary;
}

.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: $primary;
}

.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: $primary;
}

.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: $primary;
}

.navbar-dark .navbar-nav .nav-link {
  color: $white !important;
  text-transform: uppercase;
  font-size: 1rem;
  padding: 0;
}

.navbar-brand {
  margin-right: 6.5rem !important;
}

.navbar-nav .nav-item {
  margin-right: 2.5rem;
  line-height: 4.818rem;

  .nav-link {
    border-bottom: 3px solid transparent;

    &.show {
      border-bottom-color: $primary;
    }
  }
}

.nav-item .dropdown-menu {
  line-height: 1.9;
}

.dropdown-menu[data-bs-popper] {
  margin-top: 0;
  border-radius: 0;
}

.btn {
  font-weight: bold;
  line-height: 2;
  font-size: 1rem;
  border-radius: 0;
  text-transform: uppercase;
}

.btn-primary {
  @include button-variant($primary, $primary, $white);
}

.btn-outline {
  border: 1px solid $secondary;
}

.form-control,
.form-select {
  font-size: 1rem;
  line-height: 2;
  border-radius: 0;
}

.accordion-item {
  background-color: $tabInnerBg;
}

.accordion-button,
.accordion-button:not(.collapsed) {
  box-shadow: none;
  background-color: $tabInnerBg;
  color: $secondary;
  font-weight: bold;
  font-size: 1.125rem;
}

.accordion-button::after {
  background-image: url(/icons/plus.svg);
}

.accordion-button:not(.collapsed)::after {
  background-image: url(/icons/minus.svg);
}

.accordion-item {
  border-width: 0 0 1px;
  border-radius: 0 !important;
}

.breadcrumb {
  a {
    color: $cardLinkColor;
    text-decoration: none;
  }
}

.breadcrumb-item.active {
  color: $secondary;
  font-weight: bold;
}

.breadcrumb-item.d-md-none {
  &::before {
    display: none;
  }
}
:root {
  --bs-breadcrumb-divider: "";
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.25rem;

  &:before {
    background: url(/icons/arrow-right.svg) no-repeat;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    margin-top: 0.25rem;
    margin-top: 0.15rem;
  }
}

// common
.fw-500 {
  font-family: $fontFamilyMedium;
}

.fw-600 {
  font-family: $fontFamilyMedium;
}

.fw-black {
  font-family: $fontFamilyBlack;
}

h5 {
  margin-bottom: 1rem;
}

label {
  font-size: 0.875rem;
}

.svg-icon-alignment {
  margin-bottom: 4px;
  margin-left: 2px;
  margin-right: 2px;
}

.svg-filter-blue {
  filter: invert(20%) sepia(13%) saturate(445%) hue-rotate(169deg)
    brightness(90%) contrast(86%);
}

.svg-filter-dark {
  filter: invert(26%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(97%)
    contrast(95%);
}

.svg-filter-white {
  filter: invert(100%) sepia(100%) saturate(14%) hue-rotate(210deg)
    brightness(105%) contrast(100%);
}

.link-right {
  color: inherit;
  margin-left: auto;

  * {
    vertical-align: middle;
  }
}

h3 {
  font-weight: bold;
  a {
    color: $white;
    text-decoration: none;
  }
  a:hover {
    color: $primary;
  }
}

small {
  a {
    color: $white;
    text-decoration: none;
  }
  a:hover {
    color: $primary;
  }
}
.d-height {
  min-height: 241px;
}
.card-md-title {
  color: $cardTitleColor;
  font-size: 1.6rem;
  a {
    color: $primary;
    text-decoration: none;
  }
  a:hover {
    color: $cardTitleColor;
  }
}
.card-title {
  color: $cardTitleColor;
  font-size: 2.6rem;
  a {
    color: $primary;
    font-size: 2rem;
    text-decoration: none;
  }
  a:hover {
    color: $cardTitleColor;
  }
}

.card-body {
  font-size: 1.125rem;
  line-height: 1.85rem;
  padding: 3.5rem;
}

.card-link {
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  color: $cardLinkColor;
}
// navbar
.mega-menu-wrapper {
  position: static;
}

.mega-menu {
  width: 100%;
  left: 0;
  border-bottom: 3px solid $primary;
}

.mega-menu-inner {
  margin-left: 242px;
}

.list-megamenu {
  list-style: none;

  a {
    color: $tabLinkColor;
    text-decoration: none;

    &.active,
    &:hover,
    &:focus {
      color: $secondary;
    }
  }
}

.dropdown-menu.show {
  animation-name: slidenavAnimation;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-fill-mode: forwards;

  -webkit-animation-name: slidenavAnimation;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;

  -moz-animation-name: slidenavAnimation;
  -moz-animation-duration: 0.5s;
  -moz-animation-iteration-count: 1;
  -moz-animation-timing-function: ease;
  -moz-animation-fill-mode: forwards;
}

@keyframes slidenavAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes slidenavAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

// footer styles

.footer-links a {
  color: #fff;
  text-decoration: none;
}

.help-section-row {
  color: $secondary;
  font-size: 1.125rem;
  font-weight: normal;

  h3 {
    font-weight: bold;
    font-size: 2.6rem;
  }

  .btn {
    min-width: 245px;
  }
}

.footer-logo-section {
  margin-bottom: 2.5rem;
}

.footer-subheader {
  font-size: 1.125rem;
  font-weight: bold;
}

.footer-short-nav {
  color: $footerLinkColor;

  .list-inline-item {
    margin-left: 1.5rem;

    a {
      color: inherit;
      text-decoration: underline;
    }
  }
}

.social-icons-list {
  margin-bottom: 1rem;

  li.list-inline-item {
    margin-right: 1.5rem;
  }
}

.footer-divider {
  background-color: $footerDividerColor;
  height: 2px;
  opacity: 1;
}

.footer-bottom-line {
  padding: 1rem 0 2.5rem;
}

.copyright-text {
  font-size: 1rem;
}

// video-display
.video-display {
  iframe {
    display: block;
    height: 560px;
    width: 100%;
  }

  @media (max-width: 767px) {
    iframe {
      height: 320px;
    }
  }
}

///

.video_wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
}

// iframe {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
// }

.video-btn {
  text-decoration: none;
  color: #333 !important;
}

.tag {
  .type {
    background: #c91728;
    font-size: 0.8rem;
    color: white;
    display: inline-block;
    padding: 0.15rem 0.5rem;
    border-radius: 1rem;
    margin-right: 3px;

    &:not(:first-child) {
      margin-left: 3px;
    }
  }
}

///

// image-layout-info
.image-layout-info {
  font-size: 1.6rem;
  font-weight: bold;
  z-index: 1;
  left: 0;
  right: 0;
  top: auto;
  bottom: 0;
  text-align: center;
  padding-bottom: 40px;
}

.custom-tabs {
  border-bottom-width: 3px;
  border-bottom-color: $bgLight;

  .nav-link {
    margin: 0 0.25rem;
    border-radius: 0;
    color: $tabLinkColor;
    text-transform: uppercase;
    font-size: 1rem;
    border: 0;
    font-weight: bold;

    &:hover,
    &:focus,
    &:link,
    &:visited {
      border-color: transparent;
    }
  }

  .nav-link.active {
    border: 0;
    margin-bottom: -3px;
    border-bottom: 3px solid $primary;
    background: none;
    color: $secondary;
    font-weight: bold;
  }

  &.custom-tabs-filled-light {
    .nav-link.active,
    .nav-link {
      background: $tabBg;
      margin-bottom: 0;
      border-bottom: 0;
    }

    .nav-link.active {
      background: $tabInnerBg;
    }
  }

  &.custom-tabs-filled {
    .nav-link.active,
    .nav-link {
      background: $white;
      margin-bottom: 0;
      border-bottom: 0;
    }
  }
}

.custom-tabs-white {
  border-bottom-width: 3px;
  border-bottom-color: $bgLight;

  .nav-link {
    color: $white;
    text-transform: uppercase;
    font-size: 1rem;
    border: 0;
    font-weight: bold;

    &:hover,
    &:focus,
    &:link,
    &:visited {
      border-color: transparent;
    }
  }

  .nav-link.active {
    border: 0;
    margin-bottom: -3px;
    border-bottom: 3px solid $primary;
    background: none;
    color: $white;
    font-family: $fontFamilyBlack;
  }
}

.inner-tabs {
  background: $tabInnerBg;
  color: $secondary;
  border-bottom: 1px solid $carouselIndicator;

  h5 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    line-height: 1.5rem;

    small {
      font-size: 1.125rem;
      line-height: 1.85rem;
    }
  }

  @media (max-width: 767px) {
    background-color: transparent;

    .form-group label {
      display: none;
    }
  }
}

.checklist {
  list-style: none;
  margin: 0;
  color: $cardLinkColor;
  font-size: 1rem;

  li {
    position: relative;
    margin-bottom: 0.75rem;

    &:before {
      content: "";
      display: block;
      position: absolute;
      right: 100%;
      top: 0.15rem;
      margin-right: 0.85rem;
      background: url(/icons/check.svg) no-repeat center center;
      width: 1rem;
      height: 1.1rem;
    }
  }
}

.info-table {
  td {
    text-align: right;
  }
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: $table-striped-bg;
}

.table-striped > tbody > tr:nth-of-type(even) > * {
  background-color: $table-striped-alt-bg;
}

.product-catalog-section,
.component-area {
  h4 {
    font-weight: bold;
    font-size: 2rem;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid $smNavItemBorder;
  }

  @media (max-width: 767px) {
    h4 {
      font-size: 1.75rem;
    }
  }
}

.component-area {
  margin-bottom: 2.5rem;

  .component-header {
    font-weight: bold;
    font-size: 2rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid $smNavItemBorder;
  }

  li:first-letter {
    text-transform: uppercase;
  }

  .btn {
    margin: 2rem 0 1rem;
  }

  .btn-pdf {
    border: 1px solid $secondary;
  }

  ul li {
    padding: 0.175rem 0;
  }

  ul ul {
    padding-top: 1rem;
    padding-bottom: 1.3rem;
  }

  address {
    padding: 1rem;
    display: inline-block;
    border: 1px dashed #aeadad;
  }
}

.product-catalog {
  border: 1px solid $smNavItemBorder;
}

.product-caption {
  display: block;
  text-align: center;
  padding: 1rem;
  font-size: 1.25rem;
  font-weight: bold;
  color: $primary;
  background: var(--bs-light);

  a {
    text-decoration: underline;
    color: $primary;
  }
  a:hover {
    text-decoration: none;
    color: $secondary;
  }
}

.product-instructions {
  text-transform: uppercase;
  display: block;
  text-align: center;
  padding: 0.75rem 0.75rem 0.55rem;
  font-size: 0.85rem;
  color: $cardTitleColor;
}

.assoc-product-list {
  margin: 0;
  padding: 0;
  list-style: none;

  .assoc-product-img {
    width: 6rem;
  }

  li ~ li {
    border-top: 1px solid $smNavItemBorder;
  }
}

.banner {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  padding: 30px 0;
  height: 314px;
}

//override form
.form-floating > .form-control,
.form-floating > .form-select {
  height: auto;
}

// override carousel
.carousel-indicators {
  margin-bottom: -1rem;

  [data-bs-target] {
    background: $carouselIndicator;
    border: 0;
    width: 8px;
    height: 8px;
    border-radius: 20px;
    opacity: 1;
  }

  .active[data-bs-target] {
    background: $carouselIndicatorActive;
  }
}

.gradient {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.75) 100%
  );
}

//form overrides
.form-check-input {
  margin-top: 0.3em;
}

.contact-message {
  height: 278px;
}

//alerts
.alert-primary {
  background: $secondary;
  padding: 1rem;
  color: $white;
  border: none;
}

.alert-danger {
  background: $primary;
  padding: 1rem;
  color: $white;
  border: none;
}

//lists
.list-inline-special {
  .list-inline-item:not(:last-child) {
    margin-right: 0.25rem !important;
  }

  .list-inline-item {
    padding-top: 0.5rem !important;
  }
}

//custom dropdown header
.custom-header-menu {
  right: 0 !important;
  left: auto !important;
  top: auto !important;
  box-shadow: none;
  margin-top: 2px !important;
  border: none;
  border-bottom: 2px solid #ed1b2f;
  background: #f7f7f7;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #ed1b2f;
}

//image slider
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: #333;
}

// user list
.anchor-menu-links {
  a {
    color: #e74c3c;
    padding: 0.25rem 0;
    display: block;
  }
}

//forms
/*label {
    margin-bottom: 0.5rem !important;
}*/

//font sizes
.fs-sm {
  font-size: 0.75rem;
}

// react-carousel
.alice-carousel__dots-item {
  width: 15px !important;
  height: 15px !important;
}

.alice-carousel__dots-item.__active {
  background-color: #ed1b2f !important;
}

@media screen and (min-width: 1024px) {
  .product-category-less-4 {
    .alice-carousel__dots {
      display: none;
    }
  }
}

.aboutusimg {
  position: relative;

  .aboutusimgheadline {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px;
    display: block;
    width: 70%;
    height: auto;
    z-index: 2;
  }

  .gradientoverlay {
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.75) 100%
    );
    z-index: 1;
  }
}

.paintRycoTeal {
  background-color: #1f393c;
}

.aboutusicon {
  margin-bottom: 20px;
  margin-top: 30px;
}

.aboutUsSubSection {
  h2 {
    text-align: center;
    text-transform: uppercase;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}

/* Resource card */
.resource-card {
  display: inline-block;

  /* PRIMARY/RYCO DARK TEAL */
  .dark-teal {
    background: #142b35;
    color: #fff;
    height: 125px;
  }
}

.zoom-img:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.zoom-img {
  -webkit-transition: 0.7s -webkit-transform;
  transition: 0.7s-webkit-transform;
  transition: 0.7s transform;
  transition: 0.7s transform, 0.7s-webkit-transform;
}

@media (max-width: 991px) {
  .aboutusimg {
    margin-top: 1rem !important;
  }
}

@import "./responsive";

.pages-metadata {
  p {
    text-decoration: underline;
  }
  p:hover {
    text-decoration: none;
  }
}

.no-decoration {
  text-decoration: none;
}

.my-modal-wrapper {
  width: 665px;
  height: 83vh;
}

.my-modal {
  height: 83vh;
  width: 100%;
  padding: 5px;
}

.my-modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 111000005;
}
.center-modal-overlay {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 111000005;
}

.my-modal-body {
  background-color: #142b35;
  border: white 5px solid;
  border-radius: 50px;
  overflow: hidden;
}

.my-modal-header {
  display: flex;
  justify-content: flex-end;
  font-size: 25px;
}

.ca-container {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  height: 79vh;
  margin-bottom: 2px;
}
.ca-container iframe,
.ca-container object,
.ca-container embed {
  top: 0;
  left: 0;
  width: 100%;
  height: 79vh;
}

.center-align-container {
  display: flex;
  justify-content: center;
  align-items: center; /* Optional: Center vertically as well */
}

.my-com-modal {
  background-color: transparent !important;
}

.my-com-modal-header {
  display: flex;
  justify-content: flex-end;
  font-size: 25px;
  border-bottom: none !important;
}

.modal-content {
  background-color: transparent !important;
  border: none !important;
}

.my-com-modal-body {
  background-color: #142b35;
  border: white 5px solid;
  border-radius: 50px;
  overflow: hidden;
  padding-bottom: 0px !important;
}

.ca-com-container-wrapper {
  position: relative;
  overflow: hidden;
  width: 108%;
  height: 85vh;
  top: -15px;
  left: -15px;
  //background-color: #c91728;
}

@media screen and (max-width: 1024px) {
  .ca-com-container-wrapper {
    width: 111%;
  }
}

.ca-com-container {
  position: relative;
  overflow: hidden;
  top: -20px;
  left: -20px;
  height: 84vh;
  margin-bottom: 2px;
}

.ca-com-container iframe,
.ca-com-container object,
.ca-com-container embed {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 84vh;
  overflow: hidden;
}
.category-header {
  margin-bottom: 0 !important;
}

