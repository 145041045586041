@media (max-width: 1399px) {
    .navbar-nav .nav-item {
        line-height: 4rem;
    }
    .mega-menu-inner {
        margin-left: 0;
    }
    .card-body {
        padding: 2rem;
    }
}
@media (max-width: 991px) {
    .navbar-nav .nav-item {
        line-height: 4rem;
        margin-right: 2rem;
    }
    .image-layout-info {
        font-size: 1.2rem;
        padding-bottom: 2rem; 
    }
 
}
.slider-sm {
    @include make-grid-columns();

    @media (min-width: 768px) {
        .carousel-item {
            display: block;
            margin-right: 0;
        }
    }
}
@media (min-width: 768px) {
    .flex-slider-sm {
        .slides > li {
            display: block;
        }
    }
}
.accordion-sm {
    @media (min-width: 768px) {
        .collapse:not(.show) {
            display: block;
        }
        &.no-bg {
            .card-header {
                background-color: transparent;
                padding: 24px 0;
                margin-bottom: 2rem;
            }
            .table>:not(caption)>*>* {
                border-bottom-width: 0;
            }
            .accordion-button,
            .table-striped > tbody > tr:nth-of-type(odd) > *,
            .accordion-item {
                background: transparent;
                box-shadow: none;
                border: 0;
            }
            .accordion-body {
                padding: 0;
            }
        }
    }
    @media (max-width: 767px) {
        background: none !important;
    }
}
@media (max-width: 767px) {
    .banner {
        height: 125px;
    }
    .gradient {
        background: none;
    }
    .dropdown-menu {
        border: 0; 
    }
    .contact-message {
        height: auto;
    }

    .list-megamenu {
        border-bottom: 2px solid $primary !important;
    }

    .card-title {
        font-size: 2rem;
    }
    .card-body {
        padding: 1rem;
        font-size: 0.85rem;
        line-height: 1.6;
    }
    .help-section-row h3 {
        font-size: 2rem;
    }
    .help-section-row .btn {
        min-width: 8rem;
    }
    .form-control, .form-select {
        min-width: 180px;
    }
    .search-form {
        .btn {
            min-width: 150px;
        }
    }
    .navbar-brand {
        margin: 0;
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
        img {
            max-width: 110px;
        }
    }
    .mega-menu-inner {
        padding: 0 !important;
    }
    .mega-menu {
        width: auto;
    }
    .list-megamenu {
        padding-left: 1rem;
        margin-bottom: .5rem;
    }
    .navbar-brand {
        margin-left: 1rem;
    }
    .navbar-toggler {
        margin-right: 1rem;
    }
    .navbar-collapse {
        background: $white;
        padding: 1rem;
    }
    .navbar-nav .nav-item {
        line-height: 3rem;
        margin-right: 0;
        .nav-link {
            color: $secondary !important;
            border-bottom-width: 2px;
            border-bottom-color: $smNavItemBorder;
        }
        &:last-child .nav-link {
            border-bottom-color: transparent;
        }
    }

    .custom-tabs {
        .nav-link {
            font-size: .8rem;
            padding-left: .4rem;
            padding-right: .4rem;
        }
    }

    .footer-short-nav .list-inline-item {
        margin-left: 0;
    }

    .footer-bottom-line {
        padding: 0 0 1rem;
    }
    .footer-logo-section {
        img {
            max-width: 180px;
        }
    }
    .footer-links {
        > li {
            font-size: 0.85rem;
            min-width: 44%;
            display: inline-flex;
            > a {
                text-decoration: underline;
            }
        }
    }
    .footer-short-nav {
        font-size: 0.85rem;
    }

    .help-section-row {
        h3 {
            font-size: 1.25rem;
        }
        p {
            font-size: 1rem;
        }
    }

    .breadcrumb-item {
        font-size: .85rem;
        display: none;
        &:nth-last-child(2),
        &:nth-last-child(1) {
            display: block;
        }
        & + &::before {
            margin-right: .25rem;
        }
    }

    .accessibility-text {
        color: #142B35 !important;
    }
    /*.btn-primary {
        background: #AC1E37 !important;
        border-color: #AC1E37 !important;
    }*/
}

@media (max-width: 479px) { 
    .form-control, .form-select {
        min-width: 140px;
    }
}